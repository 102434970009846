<template>
  <v-container fluid v-if="activeProject">
    <v-card min-width="1100px" class="text-no-wrap">
      <v-toolbar short>
        <v-col>
          <v-select
            name="segmentFilter"
            @change="updateDropdown('SEGMENT', segmentSelect.active)"
            v-model="segmentSelect.active"
            :items="segmentSelect.items"
            :loading="!segmentSelect.items"
            label="Segment"
            hide-details
            outlined
            dense
          >
            <div class="v-list-item__content" slot="item" slot-scope="{ item }">
              <div :class="{ 'v-list-item__title': 1, 'primary--text': item.active }">
                {{ item.text }}
              </div>
            </div>
          </v-select>
        </v-col>
        <v-col>
          <v-select
            name="companyViewFilter"
            @change="updateDropdown('COMPANY', company.viewSelect.active)"
            :disabled="showOnlyContact"
            v-model="company.viewSelect.active"
            :items="company.viewSelect.items"
            :loading="company.viewSelect.items.length == 0"
            label="Company View"
            hide-details
            outlined
            dense
          ></v-select>
        </v-col>
        <v-col>
          <v-select
            name="contactViewFilter"
            @change="updateDropdown('CONTACT', contact.viewSelect.active)"
            v-model="contact.viewSelect.active"
            :items="contact.viewSelect.items"
            :loading="contact.viewSelect.items.length == 0"
            label="Contact View"
            hide-details
            outlined
            dense
          ></v-select>
        </v-col>

        <v-divider vertical />

        <template v-for="(item, i) in actionItem">
          <ActionButton
            :icon="item.icons"
            :key="i"
            :toggle="item.toggle"
            :disabled="item.action == 'CreateContact' && !selectedCompany"
            :tooltip="item.tooltip"
            :active-tooltip="item.activeTooltip"
            @action-click="handleAction(item.action)"
          />
        </template>
      </v-toolbar>

      <v-divider />
      <DataList
        ref="dataList"
        :selectedCompany.sync="selectedCompany"
        :companyHeaders="company.headers"
        :contactHeaders="contact.headers"
        :showOnlyContact="showOnlyContact"
        :ccmsId="this.activeProject"
      />
    </v-card>

    <SearchDialog
      ref="searchDialog"
      type="basic"
      :ccmsId="activeProject"
      :dialog.sync="searchDialog"
      @search="updateDropdown('SEGMENT', 'SEARCHED_RESULT')"
    />
    <VerifyLeadsBar @verifyleads="fetchSearchResult" />
  </v-container>
  <v-col v-else-if="activeProject !== null" cols="11">
    <p>Please start on a project first.</p>
  </v-col>
</template>

<script>
import ActionButton from "@/components/util/ActionButton";
import DataList from "@/components/database/data/DataList";
import VerifyLeadsBar from "@/components/telemarketer/calllist/VerifyLeadsBar";
import SearchDialog from "@/components/database/data/search/SearchDialog";

export default {
  components: {
    ActionButton,
    DataList,
    VerifyLeadsBar,
    SearchDialog
  },
  data() {
    return {
      selectedCompany: undefined,
      searchDialog: false,
      showOnlyContact: false,
      company: {
        headers: [],
        viewSelect: {
          items: [],
          active: null
        }
      },
      contact: {
        headers: [],
        viewSelect: {
          items: [],
          active: null
        }
      },
      segmentSelect: {},
      actionItem: [
        {
          icons: "mdi-reload",
          tooltip: "Get Real-time Data",
          action: "Reload"
        },
        {
          icons: "mdi-account-box-multiple",
          tooltip: "Show Contact Only",
          activeTooltip: "Show Company And Contact",
          action: "ShowOnlyContact",
          toggle: true
        },
        {
          icons: "mdi-account-search",
          tooltip: "Search",
          action: "Search"
        },
        {
          icons: "mdi-account-multiple-plus",
          tooltip: "Add New Company",
          action: "CreateCompany"
        },
        {
          icons: "mdi-account-plus",
          tooltip: "Add New Contact",
          action: "CreateContact"
        }
      ]
    };
  },
  computed: {
    activeProject() {
      return this.$store.state.ccms.activeProject;
    }
  },
  watch: {
    activeProject: {
      immediate: true,
      handler() {
        this.reloadAll();
      }
    }
  },
  methods: {
    reloadAll() {
      if (this.activeProject) {
        this.fetchSegmentDD();
        this.fetchHeaderAndDV();
        this.$nextTick(() => {
          if (this.$route.query.search) {
            this.doSearch(this.$route.query.search);
          } else {
            this.reloadTable();
          }
        });
      }
    },
    handleAction(action) {
      switch (action) {
        case "ShowOnlyContact":
          this.showOnlyContact = !this.showOnlyContact;
          this.reloadTable();
          break;
        case "Reload":
          this.reloadTable();
          break;
        case "Search":
          this.searchDialog = true;
          break;
        case "CreateCompany":
          this.createNew("COMPANY");
          break;
        case "CreateContact":
          this.createNew("CONTACT");
          break;
      }
    },
    createNew(objectType) {
      if (objectType == "CONTACT") {
        let boundary = this.selectedCompany.boundary;
        let lrbtn = (boundary.min ? "0" : "1") + (boundary.max ? "0" : "1");
        this.openTMUI(
          this.selectedCompany.COMPANY_OBJECT_ID,
          0,
          { ccmsId: this.activeProject },
          { type: "cm", lrbtn: lrbtn }
        );
      } else {
        this.openTMUI(0, 0, { ccmsId: this.activeProject });
      }
    },
    updateDropdown(type, active) {
      this.$axios
        .put("/telemarketer/calllist/set_active_dd", {
          ccmsId: this.activeProject,
          value: active,
          type: type
        })
        .then(() => {
          if (type == "SEGMENT") {
            this.reloadTable();
            if (active == "SEARCHED_RESULT") {
              this.fetchSegmentDD();
            } else {
              this.segmentSelect.items = this.segmentSelect.items.map((obj) => {
                obj.active = 0;
                return obj;
              });
            }
          } else {
            this.fetchHeaderAndDV(true).then(() => {
              if (type == "CONTACT") {
                if (this.selectedCompany) {
                  this.$refs.dataList.fetchContact();
                }
              } else if (type == "COMPANY") {
                this.$refs.dataList.fetchCompany();
              }
            });
          }
        });
    },
    reloadTable() {
      this.$refs.dataList.reloadTable();
    },
    fetchSegmentDD() {
      this.$axios
        .get("/telemarketer/calllist/segment", {
          params: {
            ccmsId: this.activeProject
          }
        })
        .then((res) => {
          this.segmentSelect = res.data;
        });
    },
    fetchHeaderAndDV(skipDropdown) {
      return this.$axios
        .get("/database/data/header", {
          params: {
            ccmsId: this.activeProject
          }
        })
        .then((res) => {
          this.company.headers = res.data.company.headers;
          this.contact.headers = res.data.contact.headers;

          if (!skipDropdown) {
            this.company.viewSelect.items = res.data.company.view_list;
            this.company.viewSelect.active = res.data.company.active_view_name;
            this.contact.viewSelect.items = res.data.contact.view_list;
            this.contact.viewSelect.active = res.data.contact.active_view_name;
          }
        });
    },
    doSearch(search) {
      this.$axios
        .put("/telemarketer/calllist/set_active_search", {
          search: search,
          ccmsId: this.activeProject
        })
        .then(() => {
          this.fetchSearchResult();
        });
    },
    fetchSearchResult() {
      this.contact.viewSelect.active = "Default Verify Contact View";
      this.updateDropdown("CONTACT", this.contact.viewSelect.active);
      this.segmentSelect.active = "SEARCHED_RESULT";
      this.updateDropdown("SEGMENT", this.segmentSelect.active);
      //update search window with verify lead filter
      this.$refs.searchDialog.reload();
    }
  }
};
</script>
