<template>
  <v-snackbar v-model="snackbar" :timeout="-1" color="info">
    You have leads to verify.
    <template v-slot:action="{ attrs }">
      <v-btn outlined small @click="verify" v-bind="attrs" class="mr-1">Verify Now</v-btn>
      <v-btn outlined small @click="closeDialog" v-bind="attrs">Later</v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      snackbar: false
    };
  },
  watch: {
    activeProject: {
      immediate: true,
      handler() {
        //check verify lead once per session per campaign
        if (this.activeProject) {
          let leadsVerifyPrompted = localStorage.getItem("verifyleads") || [];
          if (!leadsVerifyPrompted.includes(this.activeProject)) {
            leadsVerifyPrompted.push(this.activeProject);
            localStorage.setItem("verifyleads", leadsVerifyPrompted);
            this.checkLeadsVerify();
          }
        }
      }
    }
  },
  methods: {
    closeDialog() {
      this.snackbar = false;
    },
    verify() {
      this.$emit("verifyleads");
      this.closeDialog();
    },
    checkLeadsVerify() {
      this.$axios
        .get("/telemarketer/calllist/check_leads_verify", {
          params: {
            ccmsId: this.activeProject
          }
        })
        .then((res) => {
          if (res.data.company > 0 || res.data.contact > 0) {
            this.snackbar = true;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  },
  computed: {
    activeProject() {
      return this.$store.state.ccms.activeProject;
    }
  }
};
</script>
